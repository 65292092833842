<template>
    <div class="m-auto" style="max-width: 960px;">
        <el-card header="基本信息" shadow="never" class="m-3">
            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">状态</span>
                    <span class="property-value">{{product.statusName}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">商品类型</span>
                    <span class="property-value">{{product.productTypeName}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">名称</span>
                    <span class="property-value">{{product.name}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">条形码</span>
                    <span class="property-value">{{product.barcode}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">编号</span>
                    <span class="property-value">{{product.number}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">计量单位</span>
                    <span class="property-value">{{product.unit}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">规格</span>
                    <span class="property-value">{{product.specification}}</span>
                </el-col>
            </el-row>
        </el-card>

        <el-card header="价格" shadow="never" class="m-3">
            <el-row :gutter="8">
                <el-col :span="8" class="property-group">
                    <span class="property-name">价格</span>
                    <span class="property-value">{{product.price}} 元</span>
                </el-col>
            </el-row>
        </el-card>

        <el-card header="库存" shadow="never" class="m-3">
            <el-row :gutter="8">
                <el-col :span="8" class="property-group">
                    <span class="property-name">期初库存量</span>
                    <span class="property-value">{{product.beginningQuantity}}</span>
                </el-col>
                <el-col :span="8" class="property-group">
                    <span class="property-name">最低库存量</span>
                    <span class="property-value">{{product.minQuantity}}</span>
                </el-col>
                <el-col :span="8" class="property-group">
                    <span class="property-name">最高库存量</span>
                    <span class="property-value">{{product.maxQuantity}}</span>
                </el-col>
            </el-row>
        </el-card>

        <el-card header="图片" shadow="never" class="m-3">
            <picture-uploader v-model="product.pictures" readonly />
        </el-card>

        <el-card header="详情" shadow="never" class="m-3">
            <div v-html="product.detail"></div>
        </el-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                id: this.$route.query.id,
                product: {},
            };
        },
        methods: {
            async loadProduct() {
                let response = await this.$axios.get('/api/Product/GetProduct', { params: { id: this.id } });
                this.product = response.data;
                document.title = this.product.name;
            },
        },
        created() {
            document.title = this.$store.state.applicationName;

            this.loadProduct();
        },
    };
</script>

<style>
    .property-group {
        line-height: 40px;
    }

    .property-name {
        display: inline-block;
        color: gray;
    }

    .property-name::after {
        content: '：';
    }

    .property-value {
        display: inline-block;
    }
</style>